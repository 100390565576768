import React, { useState } from 'react'
import { FormattedNumber } from 'react-intl'
import Box from '@mui/material/Box'
import Button from '@material-ui/core/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import PropTypes from 'prop-types'
import { defineMessages } from 'react-intl'
import { intl } from 'utilities/localization'
import { Image } from 'components/ui/image'
import gql from 'graphql-tag'
import { client } from 'utilities/we_apollo'
import FormErrorSummary from 'components/form/error_summary'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { useQuery } from '@apollo/client'
import {
  displayBanner,
  displayExceptionBanner,
  hideBanner,
} from 'redux/dispatchers'

const { formatMessage } = intl

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const messages = defineMessages({
  approve: {
    defaultMessage: 'Approve',
    id: 'retroactiveMatchRequestList.approve',
  },
  details: {
    defaultMessage: 'Details',
    id: 'retroactiveMatchRequestRow.details',
  },
  receiptNotFoundHeader: {
    defaultMessage: 'There is no receipt available for this request.',
    id: 'retroactiveMatchRequestList.receiptNotFound',
  },
  receiptNotFoundBody: {
    defaultMessage: 'This likely means that the transaction was initiated on the WeSpire platform and you are seeing it in the review tool because automatic matching is not available for this donation.',
    id: 'retroactiveMatchRequestList.receiptNotFoundBody',
  },
  reject: {
    defaultMessage: 'Reject',
    id: 'retroactiveMatchRequestList.reject',
  },
})

const UPDATE_RETROACTIVE_MATCH_REQUEST = gql`
  mutation updateRetroactiveMatchRequest(
    $retroactiveMatchRequestId: ID!
    $email: String!
    $action: String!
    $name: String!
    $charityName: String!
    $amount: Float!

  ) {
    updateRetroactiveMatchRequest(
      retroactiveMatchRequestId: $retroactiveMatchRequestId
      email: $email
      action: $action
      name: $name
      charityName: $charityName
      amount: $amount
    ) {
      errors
    }
  }
`

export const GET_AVAILABE_MATCHING_FUNDS_QUERY = gql`
  query getAvailableMatchingFunds($accountNumber: String!){
    givingaUserAvailableMatchingFunds(accountNumber: $accountNumber)
  }
`

const RetroactiveMatchModal = ({ retroactiveMatchRequest, closeModal, action, refetch }) => {
  const [open, setOpen] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { data, loading, error } = useQuery(GET_AVAILABE_MATCHING_FUNDS_QUERY, {
    variables: {
      accountNumber: retroactiveMatchRequest.user.accountNumber,
    },
  })
  const availableMatchingFunds = data?.givingaUserAvailableMatchingFunds

  const updateRequestStatus = (retroactiveMatchRequest) => {
    hideBanner()
    setIsSubmitting(true)

    const mutation = UPDATE_RETROACTIVE_MATCH_REQUEST
    const variables = {
      retroactiveMatchRequestId: retroactiveMatchRequest.originalTransaction.id,
      email: retroactiveMatchRequest.user.email,
      name: retroactiveMatchRequest.user.name,
      charityName: retroactiveMatchRequest.charity.name,
      amount: retroactiveMatchRequest.request.amount,
      action
    }

    client
      .mutate({
        mutation,
        variables,
      })
      .then(
        ({
          data: {
            ['updateRetroactiveMatchRequest']: { errors },
          },
        }) => {
          setIsSubmitting(false)

          if (!errors) {
            refetch()
            closeModal(true)
            displayBanner({
              content: 'Match Request was successfully updated.',
              variant: 'success',
            })
          } else {
            closeModal(true)
            displayBanner({
              as: 'div',
              content: <FormErrorSummary errors={errors} />,
              fullWidth: true,
              variant: 'error',
            })
          }
        }
      )
      .catch(() => {
        setIsSubmitting(false)

        displayExceptionBanner({
          fullWidth: true,
          operation: 'update this Retroactive Match Request'
        })
      })
  }

  const displayReceipt = (url) => {
    if(url && url?.toLowerCase()?.includes(".pdf")) {
      return (
        <embed src={retroactiveMatchRequest.originalTransaction.activityId} type="application/pdf" width="100%" height="500px" />
      )
    } else {
      return (
        <>
          <img
            alt="receipt"
            className="max-w-full h-auto"
            src={retroactiveMatchRequest.originalTransaction.activityId}
          />
          <a
            href={retroactiveMatchRequest.originalTransaction.activityId}
            target="_blank"
            rel="noreferrer">
              View in new tab
          </a>
        </>
      )
    }

  }

  if (loading) {
    return <CenteredPaddedLoadingIndicator />
  }

  return (
    action === 'details' ? (
      <>
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            { !retroactiveMatchRequest?.originalTransaction?.activityId?.includes("cloudinary") ?
              <>
                <h3>{formatMessage(messages.receiptNotFoundHeader)}</h3>
                <p>{formatMessage(messages.receiptNotFoundBody)}</p>
              </>
            :
              displayReceipt(retroactiveMatchRequest?.originalTransaction?.activityId)
            }
          </Box>
        </Modal>
      </>
      ) : (
        <>
          <div>
            <Modal
              open={open}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Are you sure you want to { action === 'approved' ? 'accept' : 'reject' } this request?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div>
                    <ul>
                      <li>Requested by: { retroactiveMatchRequest.user.name }</li>
                      { action === 'approved' &&
                        <li><span>Available matching funds: 
                          <FormattedNumber currency="USD" style="currency" value={availableMatchingFunds} />
                        </span></li>
                      }

                      <li>Match amount: <FormattedNumber currency="USD" style="currency" value={retroactiveMatchRequest.request.amount} /></li>
                      <li>Charity: { retroactiveMatchRequest.charity.name }</li>
                    </ul>
                  </div>
                  { action === 'approved' &&
                    <Button
                      className="flex-shrink-none ml-2 bg-green-1"
                      style={{color: '#fffffff'}}
                      onClick={ () => updateRequestStatus(retroactiveMatchRequest) }
                      size="small"
                      disabled={isSubmitting}
                    >
                      {formatMessage(messages.approve)}
                    </Button>
                  }
                  { action === 'rejected' &&
                    <Button
                      className="flex-shrink-none ml-2 bg-red-1"
                      style={{color: '#fffffff'}}
                      onClick={ () => updateRequestStatus(retroactiveMatchRequest) }
                      size="small"
                      disabled={isSubmitting}
                    >
                      {formatMessage(messages.reject)}
                    </Button>
                  }
                </Typography>
              </Box>
            </Modal>
          </div>
        </>
      )
    )
  }

RetroactiveMatchModal.propTypes = {
  action: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  retroactiveMatchRequest: PropTypes.shape({
    charity: PropTypes.shape({
      ein: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    user: PropTypes.shape({
      accountNumber: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    originalTransaction: PropTypes.shape({
      id: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      activityId: PropTypes.string.isRequired,
    }),
    request: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired
    }),
  }).isRequired,
}

export { RetroactiveMatchModal }