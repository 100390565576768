// Vendor
import React from 'react'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormattedNumber } from 'react-intl'

// WeSpire
import Stack from 'components/ui/stack'
import { Heading } from 'components/ui/heading'
import { Button as BaseButton } from 'components/ui/button'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { sharedTranslations } from 'components/shared/translations'

const { formatMessage } = intl

const messages = {
  total: { id: 'shared.total' },
  yourOrder: { id: 'shared.yourOrder' },
}

export const CarbonReducingOverview = ({
  actionLabel,
  amount,
  button,
  currency,
  disabled,
  onClick,
  projectName,
  to,
  total,
}) => {
  return (
    <Paper className="p-4 bg-off-white w-100">
      <Stack space={3}>
        <Heading level={2}>{formatMessage(messages.yourOrder)}</Heading>

        <div className="row">
          <div className="[ col-8 ]">
            <p className="[ fs-1 fw-semi-bold ] [ lh-md ]">
              {`${projectName} | ${amount} ${sharedTranslations.tonnes}`}
            </p>
          </div>
          <div className="[ col-4 ] [ d-flex justify-content-center flex-column ]">
            <p className="[ fs-1 fw-semi-bold ] [ lh-md ] [ text-right ]">
              <FormattedNumber
                currency={currency}
                currencyDisplay="code"
                style="currency"
                value={total}
              />
            </p>
          </div>
        </div>

        <div className="row">
          <div className="[ col-8 ]">
            <p className="[ fs-1 fw-bold ] [ lh-md ] [ text-capitalize ]">
              {formatMessage(messages.total)}:
            </p>
          </div>
          <div className="[ col-4 ] [ d-flex justify-content-center flex-column ]">
            <p className="[ fs-1 fw-bold ] [ lh-md ] [ text-right ]">
              <FormattedNumber
                currency={currency}
                currencyDisplay="code"
                style="currency"
                value={total}
              />
            </p>
          </div>
        </div>

        <BaseButton
          as={button ? 'button' : Link}
          classes={{ label: 'flex flex-column' }}
          className={cx('[ w-100 ]', disabled ? '' : '[ bg-blue ]')}
          data-test="carbon-reduction-project-contribution-link"
          disabled={disabled}
          onClick={button ? onClick : undefined}
          // This component will only ever render as an <a> unless disabled, and links
          // shouldn't have default MUI prop of role="button" unless they are meant to
          // behave like real buttons. These are legit links that only *look* like
          // buttons, so role should be omitted.
          role={undefined}
          to={to}
          variant="primary"
        >
          <span className="fs-2 fw-semi-bold lh-md text-white">
            {`${actionLabel} - `}
            <FormattedNumber
              currency={currency}
              currencyDisplay="code"
              style="currency"
              value={total}
            />
          </span>
        </BaseButton>

        <div className="d-flex flex-row justify-content-center pt-2 pb-6">
          <img
            alt="mastercard"
            src="https://res.cloudinary.com/hyetmyubn/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1659715549/static/mastercard_icon.jpg"
          />
          <img
            alt="visa"
            className="px-2"
            src="https://res.cloudinary.com/hyetmyubn/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1659715549/static/visa_icon.jpg"
          />
          <img
            alt="paypal"
            src="https://res.cloudinary.com/hyetmyubn/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency//v1659715549/static/paypal_icon.jpg"
          />
        </div>
      </Stack>
    </Paper>
  )
}

CarbonReducingOverview.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  button: PropTypes.bool,
  currency: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  projectName: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
}

CarbonReducingOverview.defaultProps = {
  button: false,
  disabled: false,
  onClick: undefined,
}
