// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import v from 'voca'
import { FormattedNumber } from 'react-intl'

// WeSpire
import { Heading } from 'components/ui/heading'
import Stack from 'components/ui/stack'
import { sharedTranslations } from 'components/shared/translations'

const CarbonReducingListItem = ({
  amount,
  currency,
  image,
  title,
  price,
  setAmount,
  subtitle,
}) => {
  const totalPrice = price * amount

  const changeAmount = (e) => {
    const newValue = v.isNumeric(parseFloat(e.target.value).toFixed(2))
      ? parseFloat(parseFloat(e.target.value).toFixed(2))
      : 0
    setAmount(newValue)
  }

  return (
    <Paper className="p-4 bg-off-white">
      <Stack className="row" space={1}>
        {/* IMAGE */}
        <div className="col-md-3">
          <div
            className="[ bg-img bg-img--cover ] [ position-relative ]"
            style={{
              overflow: 'hidden',
              paddingBottom: '100%',
            }}
          >
            <img
              alt={title}
              className="[ h-100 w-100 ] [ object-fit-cover ] [ position-absolute ]"
              src={image}
            />
          </div>
        </div>

        {/* CENTER */}
        <div className="[ col-md-9 ] [ d-flex flex-column justify-content-between ]">
          <Stack space={1}>
            <div className="row">
              <div className="col-9">
                <Heading level={2}>{title}</Heading>
                <Heading level={3}>{subtitle}</Heading>
              </div>

              <div className="col-3">
                <p className="[ text-right ] [ fs-3 fs-semi-bold ]">
                  <FormattedNumber
                    currency={currency}
                    currencyDisplay="code"
                    style="currency"
                    value={totalPrice}
                  />
                </p>
              </div>
            </div>
          </Stack>
          <Stack className="mt-4" space={1}>
            <div className="d-flex flex-row justify-content-between">
              <TextField
                color="primary"
                defaultValue={amount}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: 1,
                  step: 0.25,
                }}
                label={sharedTranslations.tonnes}
                name="tonnes"
                onChange={changeAmount}
                type="number"
                variant="outlined"
              />
            </div>
          </Stack>
        </div>
      </Stack>
    </Paper>
  )
}

CarbonReducingListItem.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  setAmount: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

CarbonReducingListItem.defaultProps = {
  subtitle: null,
}

export { CarbonReducingListItem }
