/* eslint-disable formatjs/enforce-default-message */
// Vendor
import React from 'react'
import PropTypes from 'prop-types'
import { NetworkStatus } from '@apollo/client'
import { FormattedNumber } from 'react-intl'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { dateTimeDateLabel } from 'utilities/date_formatter'
import Divider from 'components/ui/divider'
import { DocumentTitle } from 'components/shared/document_title'
import Stack from 'components/ui/stack'
import { getUrlParams } from 'utilities/get_url_params'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { intl } from 'utilities/localization'
import Link from 'components/shared/link'
import { NavBreadcrumb } from 'components/ui/nav_breadcrumb'
import { getCarbonReductionLink, getCarbonReducingProjectLink } from '../utils'
import { useCarbonOrderConfirmationQuery } from '../client.utils'
import { Redirect } from 'react-router'

const messages = {
  backToFootprint: { id: 'carbonOrderConfirmation.backToFootprint' },
  carbonReduction: { id: 'carbonReduction.title' },
  errorMessage: { id: 'carbonOrderConfirmation.errorMessage' },
  forSupporting: { id: 'carbonOrderConfirmation.forSupporting' },
  funded: { id: 'shared.funded' },
  heading: { id: 'carbonOrderConfirmation.title' },
  myFootprint: { id: 'carbonFootprint.myFootprint' },
  paymentMethod: { id: 'shared.paymentMethod' },
  processed: { id: 'shared.processed' },
  projectPayment: { id: 'carbonOrderConfirmation.projectPayment' },
  projects: { id: 'shared.projects' },
  thankYou: { id: 'shared.thankYou' },
}

const { formatMessage } = intl

const LabelValueRow = ({ label, value }) => (
  <p>
    <span className="text-uppercase text-black-3 fs-3 fw-semi-bold">
      {label}:
    </span>
    <span className="pl-2 text-black-1 fs-3 fw-semi-bold">{value}</span>
  </p>
)
LabelValueRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

export const CarbonOrderConfirmationPage = () => {
  const orderId = getUrlParams().soid

  if (!orderId) {
    return <Redirect to="/carbon_footprint" />
  }

  const { carbonReducingContribution, networkStatus } =
    useCarbonOrderConfirmationQuery({ orderId })

  if (networkStatus === NetworkStatus.loading) {
    return <CenteredPaddedLoadingIndicator />
  }

  const links = [
    {
      href: '/carbon_footprint',
      text: formatMessage(messages.myFootprint),
    },
    {
      href: getCarbonReductionLink.href,
      text: formatMessage(messages.carbonReduction),
    },
    ...(carbonReducingContribution
      ? [
          {
            href: getCarbonReducingProjectLink.href(
              carbonReducingContribution.projectId
            ),
            text: carbonReducingContribution.projectName,
          },
          {
            href: window.location.href,
            text: formatMessage(messages.projectPayment),
          },
        ]
      : [
          {
            href: window.location.href,
            text: formatMessage(messages.projectPayment),
          },
        ]),
  ]
  return (
    <DocumentTitle title={formatMessage(messages.heading)}>
      <NavBreadcrumb
        className="nav-breadcrumbs bg-white lh-xs px-2 py-3"
        links={links}
      />

      <CenteredContent className="mt-5 px-lg-6">
        <Stack className="d-flex flex-col align-items-center pb-4" space={0}>
          {!carbonReducingContribution ? (
            <>
              <Icon
                className="text-black-4"
                iconName="alert_circle"
                style={{ fontSize: '9rem' }}
                title={formatMessage(messages.thankYou)}
              />
              <p
                className="fs-4"
                data-test="carbon-reduction-contribution-error"
              >
                {formatMessage(messages.errorMessage)}
              </p>
            </>
          ) : (
            <>
              <Icon
                className="text-green"
                iconName="check_circle"
                style={{ fontSize: '10rem' }}
                title={formatMessage(messages.thankYou)}
              />
              <Heading className="fs-7 fw-bold" level={2}>
                {formatMessage(messages.thankYou)}!
              </Heading>
              <p
                className="fs-5 fw-semi-bold"
                data-test="carbon-reduction-contribution-thank-you"
              >
                {formatMessage(messages.forSupporting)}
              </p>
            </>
          )}
        </Stack>
        {carbonReducingContribution && (
          <>
            <Divider />
            <Stack
              className="py-4"
              data-test="carbon-reduction-contribution-receipt"
              space={2}
            >
              <LabelValueRow
                label={formatMessage(messages.projects, { count: 1 })}
                value={carbonReducingContribution.projectName}
              />
              <LabelValueRow
                label={formatMessage(messages.funded)}
                value={
                  <FormattedNumber
                    currency={carbonReducingContribution.currency}
                    style="currency"
                    value={carbonReducingContribution.amount}
                  />
                }
              />
              <LabelValueRow
                label={formatMessage(messages.processed)}
                value={dateTimeDateLabel(carbonReducingContribution.datePaid)}
              />
            </Stack>
          </>
        )}
        <Divider />
        <Link
          className="d-flex align-items-center justify-content-center text-blue fw-semi-bold mt-4"
          to="/carbon_footprint"
        >
          <Icon className="fs-4 ml-1" iconName="arrow_left" title="Back" />
          <span className="fs-3">
            {formatMessage(messages.backToFootprint)}
          </span>
        </Link>
      </CenteredContent>
    </DocumentTitle>
  )
}
