/* eslint-disable formatjs/enforce-default-message */
// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import { useParams } from 'react-router-dom'
import { NetworkStatus } from '@apollo/client'
import Paper from '@material-ui/core/Paper'
import { stringify } from 'query-string'
import * as yup from 'yup'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { DocumentTitle } from 'components/shared/document_title'

import { Heading } from 'components/ui/heading'
import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { NavBreadcrumb } from 'components/ui/nav_breadcrumb'
import Stack from 'components/ui/stack'
import { FormTextFieldFormik } from '../../form/text_field_formik'

import { useCarbonReductionSummaryQuery } from '../client.utils'
import {
  getCarbonReductionLink,
  getCarbonReducingProjectLink,
  getCarbonOrderConfirmationLink,
} from '../utils'
import { Formik } from 'formik'
import { CarbonReducingOverview } from './carbon_reducing_overview'
import { validationTranslations } from 'components/form/text_field_validations'
import { getUrlParams } from 'utilities/get_url_params'

const messages = {
  carbonReducingProjects: { id: 'carbonReduction.carbonReducingProjects' },
  carbonReduction: { id: 'carbonReduction.title' },
  checkout: { id: 'shared.checkout' },
  myFootprint: { id: 'carbonFootprint.myFootprint' },
  personalDetails: { id: 'carbonFootprint.personalDetails' },
  projectPayment: { id: 'carbonOrderConfirmation.projectPayment' },
}

const formMessages = defineMessages({
  city: {
    defaultMessage: 'City',
    id: 'carbonReducingSummaryForm.city',
  },
  company: {
    defaultMessage: 'Company (optional)',
    id: 'carbonReducingSummaryForm.company',
  },
  email: {
    defaultMessage: 'Email',
    id: 'carbonReducingSummaryForm.email',
  },
  firstName: {
    defaultMessage: 'First Name',
    id: 'carbonReducingSummaryForm.firstName',
  },
  lastName: {
    defaultMessage: 'Last Name',
    id: 'carbonReducingSummaryForm.lastName',
  },
  nameOnCertificate: {
    defaultMessage: 'Name on the certificate',
    id: 'carbonReducingSummaryForm.nameOnCertificate',
  },
  street: {
    defaultMessage: 'Street Address',
    id: 'carbonReducingSummaryForm.street',
  },
  zip: {
    defaultMessage: 'Zip / Postal Code',
    id: 'carbonReducingSummaryForm.zip',
  },
})

const { formatMessage } = intl

export const CarbonReducingSummary = () => {
  const { id, amount: stringAmount } = useParams()
  const amount = parseFloat(stringAmount) || 1
  const params = getUrlParams()
  const currency = params?.currency || 'USD'

  const validationSchema = yup.object().shape({
    billing_city: yup
      .string()
      .required(formatMessage(validationTranslations.required)),
    billing_company: yup.string(),
    billing_email: yup
      .string()
      .email()
      .required(formatMessage(validationTranslations.required)),
    billing_first_name: yup
      .string()
      .required(formatMessage(validationTranslations.required)),
    billing_last_name: yup
      .string()
      .required(formatMessage(validationTranslations.required)),
    billing_name_on_certificate: yup
      .string()
      .required(formatMessage(validationTranslations.required)),
    billing_street: yup
      .string()
      .required(formatMessage(validationTranslations.required)),
    billing_zip: yup
      .string()
      .matches(
        /^[0-9]{5}(?:-[0-9]{4})?$/,
        formatMessage(validationTranslations.invalid)
      )
      .required(formatMessage(validationTranslations.required)),
  })

  const { carbonReducingProject, user, networkStatus } =
    useCarbonReductionSummaryQuery({
      currency,
      id,
    })

  if (networkStatus === NetworkStatus.error) {
    return (
      <MissingResourceSection
        errorDetails={formatMessage(messages.carbonReducingProjects)}
      />
    )
  }

  if (!carbonReducingProject || networkStatus === NetworkStatus.loading) {
    return <CenteredPaddedLoadingIndicator />
  }

  const links = [
    {
      href: '/take_action',
      text: formatMessage(messages.myFootprint),
    },
    {
      href: getCarbonReductionLink.href,
      text: formatMessage(messages.carbonReduction),
    },
    {
      href: getCarbonReducingProjectLink.href(id),
      text: carbonReducingProject?.name,
    },
    {
      href: location.pathname,
      text: formatMessage(messages.projectPayment),
    },
  ]

  function getOrderUrlParams(values) {
    return stringify({
      currency,
      [`p[${carbonReducingProject.id}]`]: amount.toFixed(2),
      r: `${window.location.origin}${getCarbonOrderConfirmationLink.href}`,
      ...values,
    })
  }

  const total = amount * carbonReducingProject.price

  return (
    <DocumentTitle title={carbonReducingProject?.name}>
      <NavBreadcrumb
        className="nav-breadcrumbs bg-white lh-xs px-2 py-3"
        links={links}
      />
      <div className="px-3">
        <Formik
          initialValues={{
            billing_city: '',
            billing_company: user.brand.name,
            billing_country_code: user.country.alpha2,
            billing_email: user.email,
            billing_first_name: user.firstName,
            billing_last_name: user.lastName,
            billing_name_on_certificate: user.name,
            billing_street: '',
            billing_zip: '',
          }}
          validationSchema={validationSchema}
        >
          {({ values, isValid, dirty, submitForm }) => (
            <CenteredContent>
              <Stack space={1}>
                <Stack className="[ my-3 ] [ row ]">
                  <Stack className="col-md-8" space={2}>
                    <Paper className="p-4 bg-off-white w-100">
                      <Heading level={2}>
                        {formatMessage(messages.personalDetails)}
                      </Heading>

                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <FormTextFieldFormik
                            label={formatMessage(formMessages.firstName)}
                            name="billing_first_name"
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <FormTextFieldFormik
                            label={formatMessage(formMessages.lastName)}
                            name="billing_last_name"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <FormTextFieldFormik
                            label={formatMessage(formMessages.email)}
                            name="billing_email"
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <FormTextFieldFormik
                            label={formatMessage(formMessages.company)}
                            name="billing_company"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <FormTextFieldFormik
                            label={formatMessage(formMessages.street)}
                            name="billing_street"
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <FormTextFieldFormik
                            label={formatMessage(formMessages.city)}
                            name="billing_city"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pt-3">
                          <FormTextFieldFormik
                            label={formatMessage(formMessages.zip)}
                            name="billing_zip"
                          />
                        </div>
                        <div className="col-md-6 pt-3">
                          <FormTextFieldFormik
                            label={formatMessage(
                              formMessages.nameOnCertificate
                            )}
                            name="billing_name_on_certificate"
                          />
                        </div>
                      </div>
                    </Paper>
                  </Stack>

                  <Stack
                    className="col-md-4 | d-flex align-items-start flex-column"
                    space={3}
                  >
                    <CarbonReducingOverview
                      actionLabel={formatMessage(messages.checkout)}
                      amount={amount}
                      button={!(isValid && dirty)}
                      currency={currency}
                      onClick={submitForm}
                      projectName={carbonReducingProject.name}
                      to={`/south-pole/${
                        carbonReducingProject.id
                      }/initiate_order?${getOrderUrlParams(values)}`}
                      total={total}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </CenteredContent>
          )}
        </Formik>
      </div>
    </DocumentTitle>
  )
}
