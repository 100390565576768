/* eslint-disable formatjs/enforce-default-message */
import React from 'react'
import { intl } from 'utilities/localization'

import { Heading } from 'components/ui/heading'

import { useUserCarbonFootprintQuery } from './client.utils'

const messages = {
  estimatedCarbonFootprint: { id: 'carbonReduction.estimatedCarbonFootprint' },
  estimatedCarbonFootprintValue: {
    id: 'carbonReduction.estimatedCarbonFootprintValue',
  },
}

const { formatMessage } = intl

export const getCarbonReductionLink = {
  href: '/carbon_reduction',
  id: formatMessage({
    id: 'carbonReduction.title',
  }),
}

export const getCarbonReducingProjectLink = {
  href: (id) => `/carbon_reducing_projects/${id}`,
  id: formatMessage({
    id: 'carbonReducingProject.title',
  }),
}

export const getCarbonReducingPaymentLink = {
  href: ({ currency, id }) =>
    `/carbon_reducing_payment/${id}${currency ? `?currency=${currency}` : ''}`,
  id: formatMessage({
    id: 'carbonOrderConfirmation.projectPayment',
  }),
}

export const getCarbonReducingSummaryLink = {
  href: ({ amount, currency, id }) =>
    `/carbon_reducing_payment/${id}/${amount}${
      currency ? `?currency=${currency}` : ''
    }`,
  id: formatMessage({
    id: 'carbonOrderConfirmation.projectPayment',
  }),
}

export const getCarbonOrderConfirmationLink = {
  href: `/carbon_reducing_contribution/order_confirmation`,
  id: formatMessage({
    id: 'carbonOrderConfirmation.title',
  }),
}

export const TotalUserCarbonFootPrint = () => {
  const { userCarbonFootprints } = useUserCarbonFootprintQuery()
  return (
    <>
      <Heading className="text-uppercase text-black-2 fs-2" level={3}>
        {formatMessage(messages.estimatedCarbonFootprint)}
      </Heading>
      {userCarbonFootprints && (
        <p className="fs-2 fw-bold">
          {formatMessage(messages.estimatedCarbonFootprintValue, {
            amount: userCarbonFootprints.grandTotal,
            big: (str) => <span className="fs-5">{str}</span>,
          })}
        </p>
      )}
    </>
  )
}
