/* eslint-disable formatjs/enforce-default-message */
// Vendor
import React, { useRef, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import { useParams } from 'react-router-dom'
import { NetworkStatus } from '@apollo/client'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// WeSpire
import { Button } from 'components/ui/button'
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { DocumentTitle } from 'components/shared/document_title'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { Image } from 'components/ui/image'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { NavBreadcrumb } from 'components/ui/nav_breadcrumb'
import Stack from 'components/ui/stack'

import {
  useCarbonReductionProjectQuery,
  useSouthpoleCurrencies,
} from '../client.utils'
import { getCarbonReductionLink, getCarbonReducingPaymentLink } from '../utils'
import { TotalUserCarbonFootPrint } from '../total_user_carbon_footprint'

const messages = {
  aboutThisProject: { id: 'carbonReducingProject.aboutThisProject' },
  carbonReducingProject: { id: 'carbonReducingProject.title' },
  carbonReduction: { id: 'carbonReduction.title' },
  fundButton: { id: 'carbonReducingProject.fundAmount' },
  fundButtonTooltip: { id: 'carbonReducingProject.fundAmountTooltip' },
  fundDescription: { id: 'carbonReducingProject.fundDescription' },
  fundThisProject: { id: 'carbonReducingProject.fundThisProject' },
  myFootprint: { id: 'carbonFootprint.myFootprint' },
  projectImpact: { id: 'carbonReducingProject.projectImpact' },
  useDifferentCurrency: { id: 'carbonReducingProject.useDifferentCurrency' },
}

const { formatMessage } = intl

export const CarbonReducingProjectDetailsPage = () => {
  const { id } = useParams()
  const [selectedCurrency, setSelectedCurrency] = useState('USD')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { carbonReducingProject, networkStatus, refetch, variables } =
    useCarbonReductionProjectQuery({
      currency: selectedCurrency,
      id,
    })
  const { availableCurrencies } = useSouthpoleCurrencies()
  const menuButtonEl = useRef(null)

  if (networkStatus === NetworkStatus.error) {
    return (
      <MissingResourceSection
        errorDetails={formatMessage(messages.carbonReducingProject)}
      />
    )
  }

  if (!carbonReducingProject || networkStatus === NetworkStatus.loading) {
    return <CenteredPaddedLoadingIndicator />
  }

  const links = [
    {
      href: '/carbon_footprint',
      text: formatMessage(messages.myFootprint),
    },
    {
      href: getCarbonReductionLink.href,
      text: formatMessage(messages.carbonReduction),
    },
    {
      href: location.pathname,
      text: carbonReducingProject?.name,
    },
  ]

  const onCurrencyChange = (isoCode) => () => {
    setSelectedCurrency(isoCode)
    refetch({ ...variables, currency: isoCode })
    setIsMenuOpen(false)
  }

  return (
    <DocumentTitle title={carbonReducingProject?.name}>
      <NavBreadcrumb
        className="nav-breadcrumbs bg-white lh-xs px-2 py-3"
        links={links}
      />
      <div className="px-3">
        <CenteredContent>
          <Stack space={1}>
            <div className="[ my-3 ] [ row ]">
              <Stack className="col-md-8" space={3}>
                <Heading data-test="carbon-reduction-project-name" level={1}>
                  {carbonReducingProject.name}
                </Heading>
                <span className="fs-2 text-uppercase text-black-2 fw-semi-bold">
                  {carbonReducingProject.countryName}
                </span>
                <section className="py-2">
                  <Image
                    alt=""
                    className="w-100"
                    responsive
                    src={carbonReducingProject.imageUrl}
                    style={{ maxHeight: 360 }}
                  />
                </section>

                <section className="mxw-9">
                  <Heading level={2}>
                    {formatMessage(messages.aboutThisProject)}
                  </Heading>

                  <p className="fs-3 lh-md">
                    {carbonReducingProject.context}
                  </p>
                  <p className="fs-3 lh-md">
                    {carbonReducingProject.description}
                  </p>
                </section>
              </Stack>

              <Stack
                className="col-md-4 | d-flex align-items-start flex-column | mt-5 mt-md-0 mt-lg-0"
                space={3}
              >
                <Paper className="p-4 bg-off-white">
                  <Stack space={3}>
                    <div className="d-flex align-items-center">
                      <Heading level={2}>
                        {formatMessage(messages.fundThisProject)}
                      </Heading>

                      {availableCurrencies?.length && (
                        <>
                          <Button
                            className="ml-auto p-0 fs-1 text-blue d-flex align-items-center"
                            data-test="currency-selector"
                            onClick={() => setIsMenuOpen(true)}
                            ref={menuButtonEl}
                            variant="text"
                          >
                            {selectedCurrency}
                            <Icon
                              className="fs-5 text-blue-1"
                              iconName="chevron_down"
                              title=""
                            />
                          </Button>
                          <Menu
                            anchorEl={menuButtonEl?.current}
                            classes={{
                              list: 'p-0',
                              paper: 'mt-5',
                            }}
                            id="currency-selector-menu"
                            keepMounted
                            onClose={() => setIsMenuOpen(false)}
                            open={isMenuOpen}
                          >
                            {availableCurrencies.map((currency) => (
                              <MenuItem
                                className="border-solid border-bottom border-black-6"
                                data-test="currency-selector-menu-item"
                                key={currency.id}
                                onClick={onCurrencyChange(currency.isoCode)}
                              >
                                <p>
                                  {`${currency.isoCode} ${
                                    currency.symbol || ''
                                  }`}
                                </p>
                              </MenuItem>
                            ))}
                          </Menu>
                        </>
                      )}
                    </div>

                    <LinkButton
                      classes={{ label: 'flex flex-column' }}
                      className="[ border border-orange-1 border-width-2 ] [ w-100 ]"
                      data-test="carbon-reduction-project-contribution-link"
                      to={getCarbonReducingPaymentLink.href({
                        currency: selectedCurrency,
                        id: carbonReducingProject.id,
                      })}
                      variant="text"
                    >
                      <span className="fw-semi-bold fs-2 lh-md text-black-1">
                        {formatMessage(messages.fundButton, {
                          amount: (
                            <FormattedNumber
                              currency={selectedCurrency}
                              style="currency"
                              value={carbonReducingProject.price}
                            />
                          ),
                          currency: selectedCurrency,
                        })}
                      </span>
                      <span className="fs-1 text-black-2">
                        {formatMessage(messages.fundButtonTooltip)}
                      </span>
                    </LinkButton>

                    <p className="fs-3 lh-md">
                      {formatMessage(messages.fundDescription)}
                    </p>
                  </Stack>
                </Paper>
                <Paper className="p-4 bg-off-white w-100">
                  <Stack>
                    <TotalUserCarbonFootPrint />
                  </Stack>
                </Paper>
                <Paper className="p-4 bg-off-white">
                  <Heading level={2}>
                    {formatMessage(messages.projectImpact)}
                  </Heading>

                  <List className="p-0" dense>
                    {carbonReducingProject.benefits
                      .split('.')
                      .map((benefit, index) => {
                        if (benefit.trim()) {
                          return (
                            <ListItem
                              className="align-items-start"
                              data-test="carbon-reduction-project-benefit"
                              disableGutters
                              divider
                              key={index}
                            >
                              <ListItemIcon className="my-2 mnw-0">
                                <Icon
                                  className="text-green fs-5"
                                  iconName="check_circle"
                                  title=""
                                />
                              </ListItemIcon>

                              <ListItemText
                                className="pl-2 m-0"
                                primary={
                                  <p className="fs-3 lh-md">
                                    {benefit.trim().concat('.')}
                                  </p>
                                }
                              />
                            </ListItem>
                          )
                        }
                        return null
                      })}
                  </List>
                </Paper>
              </Stack>
            </div>
          </Stack>
        </CenteredContent>
      </div>
    </DocumentTitle>
  )
}
