import { gql, useQuery } from '@apollo/client'
import { USERCARBONFOOTPRINTSQUERY } from 'components/carbon_footprint/utilities/queries'

const GET_CARBON_REDUCTION_PROJECTS = gql`
  query getCarbonReductionProjects(
    $countryFilter: String
    $sort: String
    $technologiesFilter: String
  ) {
    carbonReductionProjects(
      countryFilter: $countryFilter
      sort: $sort
      technologiesFilter: $technologiesFilter
    ) {
      id
      name
      country
      countryName
      price
      imageUrl
    }
  }
`
export const useCarbonReductionProjectsQuery = () => {
  const { data, networkStatus, ...other } = useQuery(
    GET_CARBON_REDUCTION_PROJECTS,
    {
      notifyOnNetworkStatusChange: true,
    }
  )

  return {
    carbonReducingProjects: data?.carbonReductionProjects,
    networkStatus,
    ...other,
  }
}

const GET_CARBON_REDUCTION_PROJECT_BY_ID = gql`
  query getCarbonReductionProject($id: ID!, $currency: String!) {
    carbonReductionProject(id: $id, currency: $currency) {
      id
      benefits
      context
      description
      name
      country
      countryName
      price
      imageUrl
    }
  }
`

const GET_CARBON_REDUCTION_SUMMARY_BY_ID = gql`
  query getCarbonReductionSummary($id: ID!, $currency: String!) {
    currentUser {
      id
      country {
        id
        alpha2
      }
      firstName
      lastName
      name
      email
      brand {
        id
        name
      }
    }
    carbonReductionProject(id: $id, currency: $currency) {
      id
      benefits
      context
      description
      name
      country
      countryName
      price
      imageUrl
    }
  }
`
export const useCarbonReductionProjectQuery = ({ id, currency }) => {
  const { data, networkStatus, ...other } = useQuery(
    GET_CARBON_REDUCTION_PROJECT_BY_ID,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        currency,
        id,
      },
    }
  )

  return {
    carbonReducingProject: data?.carbonReductionProject,
    networkStatus,
    ...other,
  }
}

export const useCarbonReductionSummaryQuery = ({ id, currency }) => {
  const { data, networkStatus, ...other } = useQuery(
    GET_CARBON_REDUCTION_SUMMARY_BY_ID,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        currency,
        id,
      },
    }
  )

  return {
    carbonReducingProject: data?.carbonReductionProject,
    networkStatus,
    user: data?.currentUser,
    ...other,
  }
}

const GET_CARBON_CONTRIBUTION_BY_ID = gql`
  query getCarbonContribution($southPoleOrderId: ID!) {
    carbonReductionContribution(southPoleOrderId: $southPoleOrderId) {
      id
      amount
      credits
      datePaid
      projectName
      projectId
      southPoleOrderId
      paymentMethod
      currency
    }
  }
`
export const useCarbonOrderConfirmationQuery = ({ orderId }) => {
  const { data, ...other } = useQuery(GET_CARBON_CONTRIBUTION_BY_ID, {
    notifyOnNetworkStatusChange: true,
    variables: {
      southPoleOrderId: orderId,
    },
  })

  return {
    carbonReducingContribution: data?.carbonReductionContribution,
    ...other,
  }
}

const GET_CARBON_REDUCTION_PROJECTS_FILTER = gql`
  query carbonReductionProjectsFilterQuery {
    carbonReductionProjectsFilter {
      selectableCountries
      selectableTechnologies
    }
  }
`

export const useCarbonReductionProjectsFilterQuery = () => {
  const { data, ...other } = useQuery(GET_CARBON_REDUCTION_PROJECTS_FILTER, {
    notifyOnNetworkStatusChange: true,
  })

  return {
    carbonReductionProjectsFilter: data?.carbonReductionProjectsFilter,
    ...other,
  }
}

export const useUserCarbonFootprintQuery = () => {
  const { data, ...other } = useQuery(USERCARBONFOOTPRINTSQUERY, {
    notifyOnNetworkStatusChange: true,
  })

  return {
    carbonFootprintModule: data?.currentUser?.brand.contentModules,
    userCarbonFootprints: data?.userCarbonFootprints[0],
    ...other,
  }
}

const GET_SOUTHPOLE_CURRENCIES = gql`
  query getSouthpolecurrencies {
    brand {
      enabledCurrencies(forSouthPole: true) {
        id
        name
        isoCode
        symbol
      }
    }
  }
`
export const useSouthpoleCurrencies = () => {
  const { data, ...other } = useQuery(GET_SOUTHPOLE_CURRENCIES, {
    notifyOnNetworkStatusChange: true,
  })

  return {
    availableCurrencies: data?.brand?.enabledCurrencies,
    ...other,
  }
}

const GET_CARBON_REDUCTION_IMPACT = gql`
  query carbonReductionImpactQuery {
    currentUser {
      id
      carbonReductionImpact
    }
  }
`

export const useCarbonReductionImpactQuery = () => {
  const { data, ...other } = useQuery(GET_CARBON_REDUCTION_IMPACT, {
    notifyOnNetworkStatusChange: true,
  })

  return {
    carbonReductionImpact: data?.currentUser?.carbonReductionImpact,
    ...other,
  }
}
