/* eslint-disable formatjs/enforce-default-message */
// Vendor
import React, { useState } from 'react'
import { NetworkStatus } from '@apollo/client'

// WeSpire
import Stack from 'components/ui/stack'
import { Heading } from 'components/ui/heading'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { intl } from 'utilities/localization'
import { LoadMoreButton } from 'components/shared/load_more_button'
import { SkeletonLoading } from 'components/ui/skeleton_loading'

import { CarbonReducingProjectCard } from './card'
import { useCarbonReductionProjectsQuery } from '../client.utils'
import { CarbonReducingProjectsListFilter } from './list_filter'

const messages = {
  carbonReducingProjects: { id: 'carbonReduction.carbonReducingProjects' },
  emptyState: { id: 'carbonReducingProjectsList.emptyState' },
  heading: { id: 'carbonReduction.supportCarbonReducingProjects' },
  projects: { id: 'shared.projects' },
}

const { formatMessage } = intl

const INITIAL_VISIBLE_PROJECTS = 9
export const CarbonReducingProjectsList = () => {
  const [isShowingAllProjects, setIsShowingAllProjects] = useState(false)
  const { networkStatus, carbonReducingProjects, refetch } =
    useCarbonReductionProjectsQuery()

  const onLoadMore = () => setIsShowingAllProjects(true)

  const onFilterParamsChange = (filterParams) => {
    refetch({ ...filterParams })
  }

  return (
    <Stack data-test="carbon-reducing-projects-list" space={2}>
      <Heading className="mb-4" level={2}>
        {formatMessage(messages.heading)}
      </Heading>

      <CarbonReducingProjectsListFilter
        onFilterParamsChange={onFilterParamsChange}
      />

      {networkStatus === NetworkStatus.error && (
        <MissingResourceSection
          errorDetails={formatMessage(messages.carbonReducingProjects)}
        />
      )}

      {(!carbonReducingProjects ||
        [
          NetworkStatus.loading,
          NetworkStatus.refetch,
          NetworkStatus.setVariables,
        ].includes(networkStatus)) && <SkeletonLoading height={400} />}

      {carbonReducingProjects && networkStatus === NetworkStatus.ready && (
        <>
          <Stack className="row" space={0}>
            {carbonReducingProjects
              .slice(
                0,
                isShowingAllProjects
                  ? carbonReducingProjects?.length
                  : INITIAL_VISIBLE_PROJECTS
              )
              .map((project) => {
                return (
                  <div
                    className="[ d-flex justify-content-center ] [ col-sm-6 col-lg-4 ] [ mb-3 px-2 ]"
                    key={project.id}
                  >
                    <CarbonReducingProjectCard {...project} />
                  </div>
                )
              })}
          </Stack>
          {carbonReducingProjects.length > INITIAL_VISIBLE_PROJECTS &&
            !isShowingAllProjects && (
              <Stack className="row d-flex justify-content-center px-3">
                <LoadMoreButton
                  className="col-sm-6"
                  loading={false}
                  newsfeedItemsCount={INITIAL_VISIBLE_PROJECTS}
                  onClick={onLoadMore}
                  pageSize={carbonReducingProjects?.length}
                  totalCount={carbonReducingProjects?.length}
                  type={messages.projects}
                />
              </Stack>
            )}

          {carbonReducingProjects.length === 0 && (
            <p
              className="fs-4 text-center fw-semi-bold"
              data-test="empty-state-message"
            >
              {formatMessage(messages.emptyState)}
            </p>
          )}
        </>
      )}
    </Stack>
  )
}
