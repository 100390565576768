// Vendor
import React from 'react'
import { FormattedNumber } from 'react-intl'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'

// WeSpire
import Divider from 'components/ui/divider'
import { Heading } from 'components/ui/heading'
import Icon from 'components/ui/icon'
import { Image } from 'components/ui/image'
import { intl } from 'utilities/localization'
import LinkButton from 'components/ui/link_button'
import Stack from 'components/ui/stack'
import { getCarbonReducingProjectLink } from '../utils'

const messages = {
  metricTonPrice: { id: 'carbonReduction.metricTonPrice' },
  supportThisProject: { id: 'carbonReduction.supportThisProject' },
}

const { formatMessage } = intl

export const CarbonReducingProjectCard = ({
  country,
  countryName,
  id,
  imageUrl,
  name,
  price,
}) => {
  return (
    <Card
      className="[ card ] [ d-flex flex-column ] [ mxw-4 flex-grow ] [ breakout ]"
      data-test="carbon-reducing-project-card"
    >
      <div className="d-block position-relative">
        <div className="aspect-ratio aspect-ratio--16-9 | bg-black-2">
          <Image
            alt=""
            aspectRatio="16:9"
            className="absolute-top-left"
            responsive
            src={imageUrl}
          />
        </div>
      </div>
      <CardContent className="[ flex-grow ] [ px-3 pb-0 ]">
        <Stack className="h-100 d-flex flex-column" space={1}>
          <Heading
            className="lh-sm mb-2 mxh-1 text-clamp-truncate--2"
            level={2}
          >
            {name}
          </Heading>

          <p
            className="fw-4 fw-semi-bold text-uppercase mt-auto"
            data-test="carbon-reducing-project-country"
          >
            {countryName}
          </p>
          <p
            className="fw-4 fw-semi-bold text-uppercase"
            data-test="carbon-reducing-project-credit-price"
          >
            {formatMessage(messages.metricTonPrice)} ={' '}
            <FormattedNumber currency="USD" style="currency" value={price} />
          </p>
        </Stack>
      </CardContent>
      <CardActions className="[ align-items-stretch justify-content-center flex-col ] [ mt-auto | px-3 pt-0 ]">
        <Divider className="mt-2" />
        <LinkButton
          className="fs-3 text-blue d-flex align-items-center [ breakout__item ]"
          to={{
            pathname: getCarbonReducingProjectLink.href(id),
          }}
          variant="text"
        >
          {formatMessage(messages.supportThisProject)}
          <Icon className="fs-4 ml-2" iconName="arrow_right" title="" />
        </LinkButton>
      </CardActions>
    </Card>
  )
}

CarbonReducingProjectCard.propTypes = {
  country: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}
