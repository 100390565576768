/* eslint-disable formatjs/enforce-default-message */
import React from 'react'

import { intl } from 'utilities/localization'

import { Heading } from 'components/ui/heading'
import Link from 'components/shared/link'
import LinearProgress from '@mui/material/LinearProgress'
import { LoadingIndicatorSize } from 'components/ui/loading_indicator'

import {
  useCarbonReductionImpactQuery,
  useUserCarbonFootprintQuery,
} from './client.utils'

const messages = {
  estimatedCarbonFootprint: { id: 'carbonReduction.estimatedCarbonFootprint' },
  estimatedCarbonFootprintValue: {
    id: 'carbonReduction.estimatedCarbonFootprintValue',
  },
  supportImpact: { id: 'carbonReduction.supportImpact' },
  takeQuizLink: {
    id: 'carbonCalculator.takeQuizLink',
  },
  takeQuizText: {
    id: 'carbonCalculator.takeQuizText',
  },
}

const { formatMessage } = intl

export const TotalUserCarbonFootPrint = () => {
  const { userCarbonFootprints, loading } = useUserCarbonFootprintQuery()
  const { carbonReductionImpact } = useCarbonReductionImpactQuery()
  const grandTotal = userCarbonFootprints?.grandTotal

  const carbonImpactSection = !!carbonReductionImpact && (
    <p className="fs-2 fw-bold" data-test="carbon-reduction-impact">
      {formatMessage(messages.supportImpact, {
        carbonReductionImpact,
      })}
    </p>
  )

  if (loading) {
    return (
      <div className="py-3">
        <LinearProgress
          className="rounded"
          data-test="total-user-carbon-footprint-loading"
          size={LoadingIndicatorSize.LARGE}
        />
      </div>
    )
  }
  return grandTotal ? (
    <>
      <Heading className="text-uppercase text-black-2 fs-2" level={3}>
        {formatMessage(messages.estimatedCarbonFootprint)}
      </Heading>
      {userCarbonFootprints && (
        <p className="fs-2 fw-bold" data-test="footprint-value">
          {formatMessage(messages.estimatedCarbonFootprintValue, {
            amount: grandTotal,
            big: (str) => <span className="fs-5">{str}</span>,
          })}
        </p>
      )}
      {carbonImpactSection}
    </>
  ) : (
    <>
      <p className="fs-3">
        <Link
          className="[ text-link ] [ d-inline text-decoration-none ] [ fw-semi-bold ]"
          data-test="carbon-calculator-link"
          to="/carbon_calculator"
        >
          {formatMessage(messages.takeQuizLink)}
        </Link>{' '}
        {formatMessage(messages.takeQuizText)}
      </p>
      {carbonImpactSection}
    </>
  )
}
