/* eslint-disable formatjs/enforce-default-message */
// Vendor
import React from 'react'
import { defineMessages } from 'react-intl'
import Paper from '@material-ui/core/Paper'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import Stack from 'components/ui/stack'
import { Heading } from 'components/ui/heading'
import { DocumentTitle } from 'components/shared/document_title'
import { intl } from 'utilities/localization'
import { NavBreadcrumb } from 'components/ui/nav_breadcrumb'

import { CarbonReducingProjectsList } from './carbon_reducing_project/list'
import { getCarbonReductionLink } from './utils'
import { TotalUserCarbonFootPrint } from './total_user_carbon_footprint'

const messages = defineMessages({
  carbonReduction: { id: 'carbonReduction.title' },
  description1: { id: 'carbonReduction.description1' },
  description2: { id: 'carbonReduction.description2' },
  descriptionTitle: { id: 'carbonReduction.descriptionTitle' },
  heading: { id: 'carbonReduction.title' },
  myFootprint: { id: 'carbonFootprint.myFootprint' },
  personalDetails: {
    defaultMessage: 'Personal Details',
    id: 'carbonFootprint.personalDetails',
  },
  unavoidableEmissionsDesc: { id: 'carbonReduction.unavoidableEmissionsDesc' },
  unavoidableEmissionsTitle: { id: 'carbonReduction.unavoidableEmissions' },
})

const { formatMessage } = intl

const links = [
  {
    href: '/carbon_footprint',
    text: formatMessage(messages.myFootprint),
  },
  {
    href: getCarbonReductionLink.href,
    text: formatMessage(messages.carbonReduction),
  },
]

export const CarbonReductionPage = () => {
  return (
    <DocumentTitle title={formatMessage(messages.heading)}>
      <NavBreadcrumb
        className="nav-breadcrumbs bg-white lh-xs px-2 py-3"
        links={links}
      />
      <div className="px-3">
        <CenteredContent className="mt-5">
          <Stack space={5}>
            <div className="row">
              <Heading className="mb-3 pl-3" level={1}>
                {formatMessage(messages.heading)}
              </Heading>
              <Stack className="col-lg-8" space={3}>
                <Stack className="d-flex flex-column">
                  <Paper className="flex-grow">
                    <Stack className="p-4" space={3}>
                      <Heading level={2}>
                        {formatMessage(messages.descriptionTitle)}
                      </Heading>
                      <p className="fs-3 text-black">
                        {formatMessage(messages.description1)}
                      </p>
                      <p className="fs-3 text-black">
                        {formatMessage(messages.description2)}
                      </p>
                    </Stack>
                  </Paper>
                </Stack>
              </Stack>

              <Stack
                className="[ col-lg-4 ] [ d-flex flex-column ] [ mt-3 mt-lg-0 ]"
                space={3}
              >
                <Stack as={Paper} className="p-3">
                  <TotalUserCarbonFootPrint />
                </Stack>

                <Paper className="flex-grow p-3">
                  <Stack space={2}>
                    <Heading
                      className="text-uppercase text-black-2 fs-2"
                      level={3}
                    >
                      {formatMessage(messages.unavoidableEmissionsTitle)}
                    </Heading>
                    <p className="fs-2">
                      {formatMessage(messages.unavoidableEmissionsDesc)}
                    </p>
                  </Stack>
                </Paper>
              </Stack>
            </div>

            <CarbonReducingProjectsList />
          </Stack>
        </CenteredContent>
      </div>
    </DocumentTitle>
  )
}
