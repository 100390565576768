// Vendor
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NetworkStatus } from '@apollo/client'

// WeSpire
import { FilterSelect } from 'components/form/filter_select'
import { useDebounce } from 'utilities/hooks/useDebounce'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { intl } from 'utilities/localization'

import { useCarbonReductionProjectsFilterQuery } from '../client.utils'

const messages = {
  countryBlank: {
    defaultMessage: 'Any Country',
    id: 'carbonReducingProjectsListFilter.countryBlank',
  },
  countryLabel: {
    defaultMessage: 'Country: ',
    id: 'carbonReducingProjectsListFilter.countryLabel',
  },
  errorDetails: {
    defaultMessage: 'Carbon-reducing Project Filters',
    id: 'carbonReducingProjectsListFilter.errorDetails',
  },
  sortBlank: {
    defaultMessage: 'Any',
    id: 'carbonReducingProjectsListFilter.sortBlank',
  },
  sortLabel: {
    defaultMessage: 'Sort By: ',
    id: 'carbonReducingProjectsListFilter.sortLabel',
  },
  sortPriceAsc: {
    defaultMessage: 'Price: Lowest',
    id: 'carbonReducingProjectsListFilter.sortPriceAsc',
  },
  sortPriceDesc: {
    defaultMessage: 'Price: Highest',
    id: 'carbonReducingProjectsListFilter.sortPriceDesc',
  },
  technologyBlank: {
    defaultMessage: 'Any Technology',
    id: 'carbonReducingProjectsListFilter.technologyBlank',
  },
  technologyLabel: {
    defaultMessage: 'Technology: ',
    id: 'carbonReducingProjectsListFilter.technologyLabel',
  },
}

const { formatMessage } = intl

export const CarbonReducingProjectsListFilter = ({ onFilterParamsChange }) => {
  const { networkStatus, carbonReductionProjectsFilter } =
    useCarbonReductionProjectsFilterQuery()

  const initialFilters = {
    countryFilter: '',
    technologiesFilter: '',
  }

  const [filterParams, setFilterParams] = useState({
    ...initialFilters,
    sort: '',
  })

  const debouncedFilterParams = useDebounce(filterParams, 32)

  useEffect(() => {
    onFilterParamsChange(debouncedFilterParams)
  }, [debouncedFilterParams])

  const onSelectChange = (id) => (ev) => {
    setFilterParams({
      ...filterParams,
      [id]: ev.target.value,
    })
  }

  if (networkStatus === NetworkStatus.error) {
    return (
      <MissingResourceSection
        errorDetails={formatMessage(messages.errorDetails)}
      />
    )
  }

  if (
    !carbonReductionProjectsFilter ||
    networkStatus === NetworkStatus.loading
  ) {
    return <CenteredPaddedLoadingIndicator />
  }

  return (
    <div
      className="bg-blue-2 p-4 [ d-flex flex-wrap align-center | d-md-block ]"
      data-test="carbon-reduction-projects-filter"
    >
      <FilterSelect
        blankLabel={formatMessage(messages.countryBlank)}
        id="countryFilter"
        label={formatMessage(messages.countryLabel)}
        onChange={onSelectChange('countryFilter')}
        value={filterParams.countryFilter}
      >
        {carbonReductionProjectsFilter.selectableCountries.map((country) => {
          return (
            <option key={country} value={country}>
              {country}
            </option>
          )
        })}
      </FilterSelect>

      <FilterSelect
        blankLabel={formatMessage(messages.technologyBlank)}
        id="technologiesFilter"
        label={formatMessage(messages.technologyLabel)}
        onChange={onSelectChange('technologiesFilter')}
        value={filterParams.technologiesFilter}
      >
        {carbonReductionProjectsFilter.selectableTechnologies.map(
          (technology) => {
            return (
              <option key={technology} value={technology}>
                {technology}
              </option>
            )
          }
        )}
      </FilterSelect>

      <FilterSelect
        blankLabel={formatMessage(messages.sortBlank)}
        id="sort"
        label={formatMessage(messages.sortLabel)}
        onChange={onSelectChange('sort')}
        value={filterParams.sort}
      >
        <option value="priceAsc">{formatMessage(messages.sortPriceAsc)}</option>
        <option value="priceDesc">
          {formatMessage(messages.sortPriceDesc)}
        </option>
      </FilterSelect>
    </div>
  )
}

CarbonReducingProjectsListFilter.propTypes = {
  onFilterParamsChange: PropTypes.func.isRequired,
}
