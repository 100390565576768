/* eslint-disable formatjs/enforce-default-message */
// Vendor
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { NetworkStatus } from '@apollo/client'

// WeSpire
import CenteredContent from 'components/application/centered_content'
import CenteredPaddedLoadingIndicator from 'components/ui/centered_padded_loading_indicator'
import { DocumentTitle } from 'components/shared/document_title'

import { intl } from 'utilities/localization'
import { MissingResourceSection } from 'components/ui/missing_resource_section'
import { NavBreadcrumb } from 'components/ui/nav_breadcrumb'
import Stack from 'components/ui/stack'
import { getUrlParams } from 'utilities/get_url_params'
import { CarbonReducingOverview } from './carbon_reducing_overview'

import { useCarbonReductionProjectQuery } from '../client.utils'
import {
  getCarbonReductionLink,
  getCarbonReducingProjectLink,
  getCarbonReducingSummaryLink,
} from '../utils'
import { CarbonReducingListItem } from './carbon_reducing_list_item'

const messages = {
  carbonReduction: { id: 'carbonReduction.title' },
  myFootprint: { id: 'carbonFootprint.myFootprint' },
  next: { id: 'shared.next' },
  projectPayment: { id: 'carbonOrderConfirmation.projectPayment' },
}

const { formatMessage } = intl

export const CarbonReducingPayment = () => {
  const { id } = useParams()
  const [amount, setAmount] = useState(1)
  const params = getUrlParams()
  const currency = params?.currency || 'USD'

  const { carbonReducingProject, networkStatus } =
    useCarbonReductionProjectQuery({
      currency,
      id,
    })

  if (networkStatus === NetworkStatus.error) {
    return (
      <MissingResourceSection
        errorDetails={formatMessage(messages.carbonReduction)}
      />
    )
  }

  if (!carbonReducingProject || networkStatus === NetworkStatus.loading) {
    return <CenteredPaddedLoadingIndicator />
  }

  const links = [
    {
      href: '/take_action',
      text: formatMessage(messages.myFootprint),
    },
    {
      href: getCarbonReductionLink.href,
      text: formatMessage(messages.carbonReduction),
    },
    {
      href: getCarbonReducingProjectLink.href(id),
      text: carbonReducingProject?.name,
    },
    {
      href: location.pathname,
      text: formatMessage(messages.projectPayment),
    },
  ]

  const total = amount * carbonReducingProject.price

  return (
    <DocumentTitle title={carbonReducingProject?.name}>
      <NavBreadcrumb
        className="nav-breadcrumbs bg-white lh-xs px-2 py-3"
        links={links}
      />
      <div className="px-3">
        <CenteredContent>
          <Stack space={1}>
            <Stack className="[ my-3 ] [ row ]">
              <Stack className="col-md-8" space={2}>
                <CarbonReducingListItem
                  amount={amount}
                  currency={currency}
                  image={carbonReducingProject.imageUrl}
                  price={carbonReducingProject.price}
                  setAmount={setAmount}
                  subtitle={carbonReducingProject.country}
                  title={carbonReducingProject.name}
                />
              </Stack>

              <Stack
                className="col-md-4 | d-flex align-items-start flex-column"
                space={3}
              >
                <CarbonReducingOverview
                  actionLabel={formatMessage(messages.next)}
                  amount={amount}
                  currency={currency}
                  disabled={!(amount >= 1)}
                  projectName={carbonReducingProject.name}
                  to={getCarbonReducingSummaryLink.href({
                    amount: amount ? amount : 1,
                    currency,
                    id,
                  })}
                  total={total}
                />
              </Stack>
            </Stack>
          </Stack>
        </CenteredContent>
      </div>
    </DocumentTitle>
  )
}
